// import { getUserFromCookie, getUserFromLocalStorage } from '~/utils/auth'

export default function ({ isServer, store, req }) {
  // If nuxt generate, pass this middleware
  if (isServer && !req) {

  }
  // if (isServer) {
  //   store.commit('auth/SET_USER', 'dddd')
  // }
  // console.log(isServer, store, req)
  // const loggedUser = isServer ? getUserFromCookie(req) : getUserFromLocalStorage()
  // store.commit('SET_USER', loggedUser)
}
