export default function ({ store, redirect, error }) {
  // If user not connected, redirect to /auth/login
  if (!store.getters.isAuthenticated) {
    return redirect('/auth/login')
    // error({
    //   message: 'You are not connected',
    //   statusCode: 403
    // })
  }
}
