import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9763a2c2 = () => interopDefault(import('../pages/multiorder.vue' /* webpackChunkName: "pages/multiorder" */))
const _d4beb662 = () => interopDefault(import('../pages/orders.vue' /* webpackChunkName: "pages/orders" */))
const _d5f21fa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _4ac450c4 = () => interopDefault(import('../pages/report.vue' /* webpackChunkName: "pages/report" */))
const _5e5979bc = () => interopDefault(import('../pages/report/account.vue' /* webpackChunkName: "pages/report/account" */))
const _26330322 = () => interopDefault(import('../pages/report/bydays.vue' /* webpackChunkName: "pages/report/bydays" */))
const _7790ed97 = () => interopDefault(import('../pages/report/portfolio.vue' /* webpackChunkName: "pages/report/portfolio" */))
const _1ca3ba4a = () => interopDefault(import('../pages/report/sales.vue' /* webpackChunkName: "pages/report/sales" */))
const _3de9b57e = () => interopDefault(import('../pages/service/index.vue' /* webpackChunkName: "pages/service/index" */))
const _830f1f20 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _9a19d152 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9a7a0134 = () => interopDefault(import('../pages/service/_id.vue' /* webpackChunkName: "pages/service/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/multiorder",
    component: _9763a2c2,
    name: "multiorder"
  }, {
    path: "/orders",
    component: _d4beb662,
    name: "orders"
  }, {
    path: "/profile",
    component: _d5f21fa2,
    name: "profile"
  }, {
    path: "/report",
    component: _4ac450c4,
    name: "report",
    children: [{
      path: "account",
      component: _5e5979bc,
      name: "report-account"
    }, {
      path: "bydays",
      component: _26330322,
      name: "report-bydays"
    }, {
      path: "portfolio",
      component: _7790ed97,
      name: "report-portfolio"
    }, {
      path: "sales",
      component: _1ca3ba4a,
      name: "report-sales"
    }]
  }, {
    path: "/service",
    component: _3de9b57e,
    name: "service"
  }, {
    path: "/auth/forgot",
    component: _830f1f20,
    name: "auth-forgot"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _9a19d152,
    name: "auth-logout"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/service/:id",
    component: _9a7a0134,
    name: "service-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
