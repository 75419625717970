import Vue from 'vue'
import ElementUI from 'element-ui'
import enLocale from 'element-ui/lib/locale/lang/en'
import ruLocale from 'element-ui/lib/locale/lang/ru-RU'
import uaLocale from 'element-ui/lib/locale/lang/ua'
import locale from 'element-ui/lib/locale'

export default (obj, inject) => {
  Vue.use(ElementUI)
  // Vue.locale('ru', ruLocale)
  // Vue.locale('en', enLocale)
  // Vue.locale('ua', uaLocale)
  locale.use(ruLocale)
  locale.use(enLocale)
  locale.use(uaLocale)
  const {
    Loading,
    MessageBox,
    Message,
    Notification
  } = ElementUI

  Vue.use(Loading.directive)

  Vue.prototype.$loading = Loading.service
  Vue.prototype.$msgbox = MessageBox
  Vue.prototype.$alert = MessageBox.alert
  Vue.prototype.$confirm = MessageBox.confirm
  Vue.prototype.$prompt = MessageBox.prompt
  Vue.prototype.$notify = Notification
  // Vue.prototype.$message = Message

  inject('message', Message)
}
