export const state = () => ({
  locales: {
    en: {
      short: 'eng',
      name: 'English'
      // messages: require('~/locales/en.json')
    },
    ru: {
      short: 'рус',
      name: 'Русский'
      // messages: require('~/locales/ru.json')
    },
    ua: {
      short: 'укр',
      name: 'Українска'
    }
  },
  locale: 'ru'
})

export const mutations = {
  SET_LOCALE (state, locale) {
    if (this.app.i18n.messages[locale]) {
      state.locale = locale
      this.app.i18n.locale = state.locale
    }
  }
}

export const actions = {

  change ({ commit }, locale) {
    // localStorage.setItem('locale', locale)
    commit('SET_LOCALE', locale)
  }

}
