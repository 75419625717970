import md5 from 'md5'

export const state = () => ({
  token: '',
  rememberme: false,
  params: {}
})

export const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_PARAMS: (state, params) => {
    state.params = params
  },
  SET_REMEMBERME: (state, value) => {
    state.rememberme = value
  }
}

export const actions = {

  login ({ commit, dispatch }, { username, password, rememberme }) {
    const token = md5('' + password)
    commit('SET_TOKEN', token)
    return this.$loading('login', () => {
      return this.$axios.$post('auth', {
        type: 'all',
        user: username,
        withfee: 1
      }).then((res) => {
        commit('SET_PARAMS', { user: res.user.userid, object: res.objects[0].code })
        commit('SET_REMEMBERME', rememberme)
        commit('LOGIN', res, { root: true })
        return res
      })
    })
  },

  logout ({ commit }) {
    commit('SET_TOKEN', null)
    commit('SET_PARAMS', null)
    commit('LOGOUT', null, { root: true })
  },

  changePassword ({ commit }, { oldpassword, newpassword }) {
    return this.$axios.$post('password', { oldpassword, newpassword }).then(res => {
      const token = md5('' + newpassword)
      commit('SET_TOKEN', token)
      return res
    })
  }
}
