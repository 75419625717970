<template>
  <div :class="{'balances': true, 'is-loading': $isLoading('balance')}" @click="reloadBalance" :title="$t('Refresh balance')">
    <transition name="el-zoom-in-center">
      <div v-if="!$isLoading('balance')">
        <div v-for="(portfolio, index) in portfolios" :key="index" class="balance-item">
          <span v-if="portfolio.amount < portfolio.limit">
            <el-tooltip :content="getSharedTitle(portfolio)">
              <i class="el-icon-share" />
            </el-tooltip>
          </span>
          <span class="balance-limit">
            {{ (portfolio.limit / 100).toFixed(2) }}
          </span>
          <span class="balance-currency">
            {{ $t('currency.short.' + portfolio.currency.id) }}
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState([
    'portfolios'
  ]),
  methods: {
    reloadBalance () {
      try {
        this.$store.dispatch('reloadBalance')
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    getSharedTitle (portfolio) {
      return this.$t('portfolio.shared', [(portfolio.amount / 100).toFixed(2) + this.$t('currency.short.' + portfolio.currency.id)])
    }
  }
}
</script>
